import React from "react";

const Footer = () => {
  return (
    <footer className="clockin-main-footer-"
      style={{ backgroundColor: "black", color: "white", padding: "10px 0", textAlign: "center" }}
    >
      4th Dimension
    </footer>
  );
};

export default Footer;
